import React, { useState } from 'react';
import {
    required,
    number,
    useTranslate,
    SaveButton,
    AutocompleteInput,
    ReferenceInput,
    TextField,
    SimpleForm,
    useRecordContext,
    maxLength,
} from 'react-admin';
import { Typography, Grid, Toolbar, Box } from '@mui/material';
import { RaBox } from 'ra-compact-ui';

import { ResourceKey } from 'constants/index';
import { TextInput, AddressForm, OptionLocation, useOptionLocation, Button } from 'modules/ui';
import { DeleteButtonWithPermissions, useHasAccess } from 'modules/auth';
import { PermissionAction } from 'modules/roles';
import { nonNullParser } from 'utils';

import { locationParentDepotValidator } from 'utils/locationParentDepotValidator';

import type { ApmDetail } from '../../types';

import ApmEditCompartments from '../ApmEditCompartments';
import ApmEditImage from '../ApmEditImage';
import { ApmAvailabilityInfo } from '../ApmAvailabilityInfo';
import { ApmHeader } from '../ApmHeader';
import { ApmStateSelect } from '../ApmStateSelect';

import * as Styled from './ApmEditForm.styles';

import { formatProbability } from '../ApmEditProbability/ApmEditProbability.utils';

const requiredValidator = required();
const requiredNumberValidator = [requiredValidator, number()];

export const ApmEditForm = () => {
    const [showAll, setShowAll] = useState<boolean>(false);
    const record = useRecordContext<ApmDetail>();
    const translate = useTranslate();
    const compartmentsLimit: number = 50;

    const access = {
        uploadImage: useHasAccess(ResourceKey.APM, PermissionAction.UPLOAD_IMAGE),
        editAvailability: useHasAccess(ResourceKey.APM, PermissionAction.EDIT_AVAILABILITY),
    };

    const { inputText, matchSuggestion } = useOptionLocation();
    return (
        <SimpleForm
            record={{
                ...record,
                availabilityProbSmall: formatProbability(record.availabilityProbSmall),
                availabilityProbMedium: formatProbability(record.availabilityProbMedium),
                availabilityProbLarge: formatProbability(record.availabilityProbLarge),
            }}
            toolbar={
                <Toolbar>
                    <Box display="flex" justifyContent="space-between" width="100%">
                        <SaveButton />
                        <DeleteButtonWithPermissions />
                    </Box>
                </Toolbar>
            }
        >
            <Grid container spacing={8} width="100%">
                <Grid item sm={12} md={7}>
                    <Box display="flex" flexDirection="column" maxWidth={360}>
                        <ApmHeader />

                        {access.editAvailability && <ApmStateSelect />}
                        <TextInput source="name" label={translate('apm.list.name')} parse={nonNullParser} />
                        <TextInput
                            source="title"
                            label={translate('apm.edit.titleField')}
                            validate={maxLength(32)}
                            parse={nonNullParser}
                        />
                        <TextInput source="identifier" label={translate('apm.list.identifier')} parse={nonNullParser} />
                        <TextInput
                            source="description"
                            label={translate('apm.edit.description')}
                            multiline
                            minRows={3}
                            parse={nonNullParser}
                        />
                        <TextInput
                            source="internalNote"
                            label={translate('apm.edit.internalNote')}
                            multiline
                            minRows={3}
                            parse={nonNullParser}
                        />
                        <TextInput
                            source="columnsOrder"
                            label={translate('apm.edit.columnsOrder')}
                            multiline
                            minRows={3}
                            validate={(v: string) =>
                                v === '' || /^\d+(,\d+)*$/.test(v)
                                    ? undefined
                                    : translate('apm.edit.columnsOrder.validationError')
                            }
                            parse={nonNullParser}
                        />

                        <Typography variant="h6" component="h3">
                            {translate('apm.edit.location')}
                        </Typography>

                        <Styled.LocationIdLayout>
                            <RaBox>
                                <TextField source="location.id" label={translate('apm.location.id')} />
                            </RaBox>
                        </Styled.LocationIdLayout>

                        <AddressForm source="location" />

                        <Styled.LatLngInput source="location.coordinates" validate={requiredNumberValidator} />

                        <ReferenceInput
                            source="depot"
                            filter={{ type: 'depot' }}
                            perPage={50}
                            reference={ResourceKey.LOCATION}
                        >
                            <AutocompleteInput
                                label={translate('apm.depot')}
                                optionText={<OptionLocation />}
                                inputText={inputText}
                                matchSuggestion={matchSuggestion}
                                validate={[
                                    requiredValidator,
                                    (value, allValues) => locationParentDepotValidator(value, allValues, record),
                                ]}
                            />
                        </ReferenceInput>
                    </Box>
                </Grid>

                {access.uploadImage && (
                    <Grid item sm={12} md={5}>
                        <Typography variant="h6" component="h3">
                            {translate('apm.edit.photo')}
                        </Typography>

                        <ApmEditImage />

                        <ApmAvailabilityInfo />
                    </Grid>
                )}
            </Grid>
            <ApmEditCompartments showAll={showAll} compartmentsLimit={compartmentsLimit} />

            {record.compartments && record.compartments.length > compartmentsLimit && (
                <Typography mt={4}>
                    {!showAll
                        ? translate('apm.edit.limit.message', {
                              limit: compartmentsLimit,
                              total: record.compartments.length,
                          })
                        : translate('apm.edit.hide.message')}
                    <Button onClick={() => setShowAll(prev => !prev)} style={{ marginLeft: '0.5rem' }}>
                        {translate(`apm.edit.button.${showAll ? 'hide' : 'show'}`)}
                    </Button>
                </Typography>
            )}
        </SimpleForm>
    );
};
