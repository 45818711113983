import { merge } from 'lodash';

import { ApiVersion, isEnvDevelopment, isLocalhost } from 'constants/index';

import type { EnvConfig } from './config.development';

const envConfig = require(`./config.${process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV}.ts`).default;

const defaultConfig = {
    appName: process.env.REACT_APP_NAME,
    devTools: isEnvDevelopment,
    api: {
        endpoints: {
            requestAuthCode: '/auth-sessions:codeGet',
            login: '/auth-sessions',
            refresh: '/auth-sessions:refresh',
            logout: '/auth-sessions:logout',
            azureRedirect: '/auth-sessions:azureRedirect',
            azureLogin: '/auth-sessions:azureLogin',
            images: '/images',
        },
    },
    adminApi: {
        endpoints: {
            prolongStorageTime: `/${ApiVersion.V1}/parcel/{parcelId}:prolongStorageTime`,
            cancelParcel: `/${ApiVersion.V1}/parcel/{parcelId}:cancel`,
            resetDirection: `/${ApiVersion.V1}/parcel/{parcelId}:resetDirection`,
            updatePaymentStateFromCsv: `/${ApiVersion.V1}/parcel:updatePaymentStateFromCsv`,
            markParcelAsDelivered: `/${ApiVersion.V1}/parcel/{parcelId}:markParcelAsDelivered`,
            markParcelAsLost: `/${ApiVersion.V1}/parcel/{parcelId}:markLost`,
            markParcelAsMissing: `/${ApiVersion.V1}/parcel/{parcelId}:markMissing`,
            markParcelAsReturned: `/${ApiVersion.V1}/parcel/{parcelId}:markReturned`,
            markParcelAsRefunded: `/${ApiVersion.V1}/parcel/{parcelId}:markRefunded`,
            parcelHistory: `/${ApiVersion.V1}/parcel/{parcelId}/history`,
            userPermissions: `/${ApiVersion.V1}/user/{userId}/permissions`,
            showLcdText: `/${ApiVersion.V1}/apm/{apmId}:showLcdText`,
            reportApmIssue: `/${ApiVersion.V1}/apm/{apmId}:reportIssue`,
            syncLocker: `/${ApiVersion.V1}/apm/{apmId}:syncLocker`,
            openCompartment: `/${ApiVersion.V1}/apm/{apmId}:openCompartment`,
            currentApm: `/${ApiVersion.V1}/parcel/{parcelId}/current-apm`,
            placeIntoLocker: `/${ApiVersion.V1}/parcel/{parcelId}:placeIntoLocker`,
        },
    },
    sentry: {
        enable: !isLocalhost,
    },
} as const;

type DefaultConfig = typeof defaultConfig;

export type Config = DefaultConfig & EnvConfig;

export default merge(defaultConfig, envConfig) as Config;
