import { RaBox } from 'ra-compact-ui';
import {
    BooleanField,
    RecordContextProvider,
    ReferenceField,
    TextField,
    useListContext,
    useRecordContext,
    useTranslate,
} from 'react-admin';

import { Box, Typography } from '@mui/material';

import { ResourceKey } from 'constants/index';
import type { PartnerData } from 'modules/partners/types';
import { Country, LatLngField } from 'modules/ui';

import { DefaultWarehouseForReturnsField } from '../DefaultWarehouseForReturnsField';
import * as Styled from './WarehousesList.styles';

export interface WarehousesListProps {}

export const WarehousesList = (props: WarehousesListProps) => {
    const translate = useTranslate();
    const { data } = useListContext();
    const partner = useRecordContext<PartnerData>();

    return (
        <>
            {data.map(record => (
                <RecordContextProvider value={record} key={record.id}>
                    <Styled.BoxedShowLayoutItem record={record}>
                        <RaBox gap={1}>
                            <RaBox display="flex" gap={24}>
                                <TextField source="id" label={translate('warehouse.id')} fontWeight={600} />
                                <BooleanField
                                    source="deleted"
                                    label={translate('partners.warehouse.disabled')}
                                    fontWeight={600}
                                />
                            </RaBox>
                            <DefaultWarehouseForReturnsField partner={partner} />
                            <TextField label={translate('warehouse.name')} source="name" fontWeight={600} />
                            <TextField source="identifier" label={translate('warehouse.identifier')} fontWeight={600} />

                            <Box display="flex" gap={4}>
                                <Box display="flex" gap={0} flexDirection="column" paddingTop={1}>
                                    <Typography variant="caption">{translate('general.address.street')}</Typography>
                                    <Typography variant="body2" fontWeight={600}>
                                        {record.street}
                                    </Typography>
                                    <Typography variant="body2" fontWeight={600}>
                                        {record.postalCode}, {record.city}
                                    </Typography>
                                    <Typography variant="body2" fontWeight={600}>
                                        <Country countryCode={record.country} />
                                    </Typography>
                                </Box>
                                <LatLngField source="coordinates" />
                            </Box>

                            <ReferenceField
                                source="depotId"
                                label={translate('warehouse.serviceArea')}
                                reference={ResourceKey.LOCATION}
                                link={false}
                            >
                                <TextField source="name" fontWeight={600} />
                            </ReferenceField>
                        </RaBox>
                    </Styled.BoxedShowLayoutItem>
                </RecordContextProvider>
            ))}
        </>
    );
};
