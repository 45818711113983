import { useRecordContext } from 'react-admin';

import config from 'config';

import { useTranslate } from 'hooks';

import { useParcelApiAction } from '../../../../hooks/useParcelApiAction';
import type { Parcel } from '../../../../types';
import { MarkParcelAsType } from '../types';

interface Options {
    onSuccess?: () => void;
}

export const useMarkParcelAsMutation = (type: MarkParcelAsType, options?: Options) => {
    const parcel = useRecordContext<Parcel>();
    const translate = useTranslate();

    const endpoint = markParcelAsTypeToEndpoint(type);

    const [state, mutate] = useParcelApiAction(endpoint, parcel?.id, {
        successMsg: translate(`parcel.detail.markAs.${type}.success`, { parcelNumber: parcel?.parcelNumber }),
        errorMsg: translate(`parcel.detail.markAs.${type}.failure`, { parcelNumber: parcel?.parcelNumber }),
        onSuccess: options?.onSuccess,
    });

    return [state, mutate] as const;
};

const markParcelAsTypeToEndpoint = (type: MarkParcelAsType) =>
    ({
        [MarkParcelAsType.Delivered]: config.adminApi.endpoints.markParcelAsDelivered,
        [MarkParcelAsType.Lost]: config.adminApi.endpoints.markParcelAsLost,
        [MarkParcelAsType.Missing]: config.adminApi.endpoints.markParcelAsMissing,
        [MarkParcelAsType.Returned]: config.adminApi.endpoints.markParcelAsReturned,
        [MarkParcelAsType.Refunded]: config.adminApi.endpoints.markParcelAsRefunded,
    }[type]);
