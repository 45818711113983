import { ResourceKey } from 'constants/index';

import { Role } from './roles';

export const PermissionAll = 'all' as const;

export type TPermissionAll = typeof PermissionAll;

export enum PermissionAction {
    LIST = 'list',
    SEARCH = 'search',
    EXPORT = 'export',
    DETAIL = 'detail',
    CANCEL = 'cancel',
    CHANGE_STATUS = 'changeStatus',
    EXTEND_STORAGE_TIME = 'extendStorageTime',
    CREATE = 'create',
    EDIT = 'edit',
    DELETE = 'delete',
    ENABLE = 'enable',
    UPLOAD_IMAGE = 'uploadImage',
    EDIT_AVAILABILITY = 'editAvailability',
    EDIT_NOTE = 'editNote',
    EDIT_PAYMENT = 'editPayment',
    EDIT_DESTINATION = 'editDestination',
    EDIT_REFERENCE_NUMBER = 'editReferenceNumber',
    EDIT_SENDER = 'editSender',
    EDIT_RECIPIENT = 'editRecipient',
    EDIT_USER_PERMISSIONS = 'editUserPermissions',
    EDIT_PARCEL_SIZE = 'editParcelSize',
    REPORT_APM_ISSUE = 'reportApmIssue',
    SHOW_LCD_TEXT = 'showLcdText',
    OPEN_COMPARTMENT = 'openCompartment',
    MARK_PARCEL_AS_DELIVERED = 'markParcelAsDelivered',
    MARK_PARCEL_AS_RETURNED = 'markParcelAsReturned',
    MARK_PARCEL_AS_REFUNDED = 'markParcelAsRefunded',
    MARK_PARCEL_AS_LOST = 'markParcelAsLost',
    MARK_PARCEL_AS_MISSING = 'markParcelAsMissing',
    CHANGE_COD_TO_PREPAID = 'changeCodToPrepaid',
    PUT_IN_LOCKER = 'putInLocker',
    REFRESH_APM_CONFIGURATION = 'refreshApmConfiguration',
}

export type ResourcePermissions = ReadonlyArray<PermissionAction>;

export type ResourcesPermissions = Partial<Record<ResourceKey, ResourcePermissions>>;
export type RolePermissions = TPermissionAll | undefined | ResourcesPermissions;

export const rolePermissions: Record<Role, RolePermissions> = {
    [Role.SUPER_ADMIN]: PermissionAll,

    [Role.MANAGER]: {
        [ResourceKey.PARCELS]: [
            PermissionAction.LIST,
            PermissionAction.SEARCH,
            PermissionAction.EXPORT,
            PermissionAction.DETAIL,
            PermissionAction.CANCEL,
            PermissionAction.CHANGE_STATUS,
            PermissionAction.EXTEND_STORAGE_TIME,
            PermissionAction.EDIT_PAYMENT,
            PermissionAction.EDIT_NOTE,
            PermissionAction.EDIT_DESTINATION,
            PermissionAction.EDIT_REFERENCE_NUMBER,
            PermissionAction.EDIT_SENDER,
            PermissionAction.EDIT_RECIPIENT,
            PermissionAction.EDIT_PARCEL_SIZE,
            PermissionAction.MARK_PARCEL_AS_DELIVERED,
            PermissionAction.MARK_PARCEL_AS_LOST,
            PermissionAction.MARK_PARCEL_AS_MISSING,
            PermissionAction.MARK_PARCEL_AS_RETURNED,
            PermissionAction.MARK_PARCEL_AS_REFUNDED,
            PermissionAction.CHANGE_COD_TO_PREPAID,
            PermissionAction.PUT_IN_LOCKER,
        ],
        [ResourceKey.APM]: [
            PermissionAction.LIST,
            PermissionAction.DETAIL,
            PermissionAction.SEARCH,
            PermissionAction.EDIT,
            PermissionAction.EDIT_AVAILABILITY,
            PermissionAction.UPLOAD_IMAGE,
            PermissionAction.SHOW_LCD_TEXT,
            PermissionAction.REPORT_APM_ISSUE,
            PermissionAction.REFRESH_APM_CONFIGURATION,
        ],
        [ResourceKey.LANES]: [
            PermissionAction.LIST,
            PermissionAction.SEARCH,
            PermissionAction.DETAIL,
            PermissionAction.EXPORT,
            PermissionAction.CREATE,
            PermissionAction.EDIT,
            PermissionAction.DELETE,
        ],
        [ResourceKey.USER]: [
            PermissionAction.LIST,
            PermissionAction.SEARCH,
            PermissionAction.EXPORT,
            PermissionAction.DETAIL,
            PermissionAction.CREATE,
            PermissionAction.EDIT,
            PermissionAction.DELETE,
            PermissionAction.EDIT_USER_PERMISSIONS,
        ],
        [ResourceKey.PARTNER]: [
            PermissionAction.LIST,
            PermissionAction.SEARCH,
            PermissionAction.EXPORT,
            PermissionAction.DETAIL,
            PermissionAction.CREATE,
            PermissionAction.EDIT,
            PermissionAction.ENABLE,
        ],
        [ResourceKey.WAREHOUSE]: [
            PermissionAction.LIST,
            PermissionAction.SEARCH,
            PermissionAction.EXPORT,
            PermissionAction.DETAIL,
            PermissionAction.CREATE,
            PermissionAction.EDIT,
        ],
    },

    [Role.OPERATIONS_MANAGER]: {
        [ResourceKey.PARCELS]: [
            PermissionAction.LIST,
            PermissionAction.SEARCH,
            PermissionAction.EXPORT,
            PermissionAction.DETAIL,
            PermissionAction.CANCEL,
            PermissionAction.CHANGE_STATUS,
            PermissionAction.EXTEND_STORAGE_TIME,
            PermissionAction.EDIT_NOTE,
            PermissionAction.EDIT_DESTINATION,
            PermissionAction.EDIT_REFERENCE_NUMBER,
            PermissionAction.EDIT_SENDER,
            PermissionAction.EDIT_RECIPIENT,
            PermissionAction.EDIT_PARCEL_SIZE,
            PermissionAction.MARK_PARCEL_AS_DELIVERED,
            PermissionAction.MARK_PARCEL_AS_LOST,
            PermissionAction.MARK_PARCEL_AS_MISSING,
            PermissionAction.MARK_PARCEL_AS_RETURNED,
            PermissionAction.PUT_IN_LOCKER,
        ],
        [ResourceKey.APM]: [
            PermissionAction.LIST,
            PermissionAction.DETAIL,
            PermissionAction.SEARCH,
            PermissionAction.EDIT,
            PermissionAction.EDIT_AVAILABILITY,
            PermissionAction.UPLOAD_IMAGE,
            PermissionAction.SHOW_LCD_TEXT,
            PermissionAction.REPORT_APM_ISSUE,
            PermissionAction.OPEN_COMPARTMENT,
            PermissionAction.REFRESH_APM_CONFIGURATION,
        ],
        [ResourceKey.LANES]: [
            PermissionAction.LIST,
            PermissionAction.SEARCH,
            PermissionAction.DETAIL,
            PermissionAction.EXPORT,
            PermissionAction.CREATE,
            PermissionAction.EDIT,
            PermissionAction.DELETE,
        ],
        [ResourceKey.USER]: [
            PermissionAction.LIST,
            PermissionAction.SEARCH,
            PermissionAction.EXPORT,
            PermissionAction.DETAIL,
            PermissionAction.EDIT_USER_PERMISSIONS,
        ],
        [ResourceKey.PARTNER]: [PermissionAction.LIST, PermissionAction.SEARCH, PermissionAction.DETAIL],
    },

    [Role.SALES_MANAGER]: {
        [ResourceKey.PARCELS]: [
            PermissionAction.LIST,
            PermissionAction.SEARCH,
            PermissionAction.EXPORT,
            PermissionAction.DETAIL,
            PermissionAction.EDIT_NOTE,
        ],
        [ResourceKey.APM]: [PermissionAction.LIST, PermissionAction.DETAIL, PermissionAction.SEARCH],

        [ResourceKey.PARTNER]: [
            PermissionAction.LIST,
            PermissionAction.SEARCH,
            PermissionAction.EXPORT,
            PermissionAction.DETAIL,
            PermissionAction.CREATE,
            PermissionAction.EDIT,
            PermissionAction.ENABLE,
        ],
        [ResourceKey.WAREHOUSE]: [
            PermissionAction.LIST,
            PermissionAction.SEARCH,
            PermissionAction.EXPORT,
            PermissionAction.DETAIL,
            PermissionAction.CREATE,
            PermissionAction.EDIT,
        ],
    },
    [Role.NETWORK_MANAGER]: {
        [ResourceKey.APM]: [
            PermissionAction.LIST,
            PermissionAction.DETAIL,
            PermissionAction.SEARCH,
            PermissionAction.EDIT,
            PermissionAction.UPLOAD_IMAGE,
            PermissionAction.EDIT_AVAILABILITY,
            PermissionAction.SHOW_LCD_TEXT,
            PermissionAction.REPORT_APM_ISSUE,
            PermissionAction.OPEN_COMPARTMENT,
            PermissionAction.REFRESH_APM_CONFIGURATION,
        ],
    },
    [Role.SALES_AND_OPERATION_MANAGER]: {
        [ResourceKey.PARCELS]: [
            PermissionAction.LIST,
            PermissionAction.SEARCH,
            PermissionAction.EXPORT,
            PermissionAction.DETAIL,
            PermissionAction.CANCEL,
            PermissionAction.CHANGE_STATUS,
            PermissionAction.EXTEND_STORAGE_TIME,
            PermissionAction.EDIT_NOTE,
            PermissionAction.EDIT_PAYMENT,
            PermissionAction.EDIT_DESTINATION,
            PermissionAction.EDIT_REFERENCE_NUMBER,
            PermissionAction.EDIT_SENDER,
            PermissionAction.EDIT_RECIPIENT,
            PermissionAction.EDIT_PARCEL_SIZE,
            PermissionAction.MARK_PARCEL_AS_DELIVERED,
            PermissionAction.MARK_PARCEL_AS_LOST,
            PermissionAction.MARK_PARCEL_AS_MISSING,
            PermissionAction.MARK_PARCEL_AS_RETURNED,
            PermissionAction.MARK_PARCEL_AS_REFUNDED,
            PermissionAction.CHANGE_COD_TO_PREPAID,
            PermissionAction.PUT_IN_LOCKER,
        ],
        [ResourceKey.APM]: [
            PermissionAction.LIST,
            PermissionAction.DETAIL,
            PermissionAction.SEARCH,
            PermissionAction.SHOW_LCD_TEXT,
            PermissionAction.REPORT_APM_ISSUE,
            PermissionAction.OPEN_COMPARTMENT,
        ],
        [ResourceKey.LANES]: [
            PermissionAction.LIST,
            PermissionAction.SEARCH,
            PermissionAction.DETAIL,
            PermissionAction.EXPORT,
            PermissionAction.CREATE,
            PermissionAction.EDIT,
            PermissionAction.DELETE,
        ],
        [ResourceKey.USER]: [
            PermissionAction.LIST,
            PermissionAction.SEARCH,
            PermissionAction.EXPORT,
            PermissionAction.DETAIL,
            PermissionAction.EDIT_USER_PERMISSIONS,
        ],
        [ResourceKey.PARTNER]: [
            PermissionAction.LIST,
            PermissionAction.SEARCH,
            PermissionAction.EXPORT,
            PermissionAction.DETAIL,
            PermissionAction.CREATE,
            PermissionAction.EDIT,
            PermissionAction.ENABLE,
        ],
        [ResourceKey.WAREHOUSE]: [
            PermissionAction.LIST,
            PermissionAction.SEARCH,
            PermissionAction.EXPORT,
            PermissionAction.DETAIL,
            PermissionAction.CREATE,
            PermissionAction.EDIT,
        ],
    },
    [Role.NETWORK_AND_SALES_MANAGER]: {
        [ResourceKey.PARCELS]: [
            PermissionAction.LIST,
            PermissionAction.SEARCH,
            PermissionAction.EXPORT,
            PermissionAction.DETAIL,
            PermissionAction.EDIT_NOTE,
        ],
        [ResourceKey.APM]: [
            PermissionAction.LIST,
            PermissionAction.DETAIL,
            PermissionAction.SEARCH,
            PermissionAction.EDIT,
            PermissionAction.UPLOAD_IMAGE,
            PermissionAction.EDIT_AVAILABILITY,
            PermissionAction.SHOW_LCD_TEXT,
            PermissionAction.REPORT_APM_ISSUE,
            PermissionAction.OPEN_COMPARTMENT,
            PermissionAction.REFRESH_APM_CONFIGURATION,
        ],
        [ResourceKey.PARTNER]: [
            PermissionAction.LIST,
            PermissionAction.SEARCH,
            PermissionAction.EXPORT,
            PermissionAction.DETAIL,
            PermissionAction.CREATE,
            PermissionAction.EDIT,
            PermissionAction.ENABLE,
        ],
        [ResourceKey.WAREHOUSE]: [
            PermissionAction.LIST,
            PermissionAction.SEARCH,
            PermissionAction.EXPORT,
            PermissionAction.DETAIL,
            PermissionAction.CREATE,
            PermissionAction.EDIT,
        ],
    },
    [Role.CUSTOMER_CARE]: {
        [ResourceKey.PARCELS]: [
            PermissionAction.LIST,
            PermissionAction.SEARCH,
            PermissionAction.EXPORT,
            PermissionAction.DETAIL,
            PermissionAction.CANCEL,
            PermissionAction.EXTEND_STORAGE_TIME,
            PermissionAction.EDIT_NOTE,
            PermissionAction.EDIT_DESTINATION,
            PermissionAction.EDIT_REFERENCE_NUMBER,
            PermissionAction.EDIT_SENDER,
            PermissionAction.EDIT_RECIPIENT,
            PermissionAction.EDIT_PARCEL_SIZE,
            PermissionAction.PUT_IN_LOCKER,
        ],
        [ResourceKey.APM]: [
            PermissionAction.LIST,
            PermissionAction.DETAIL,
            PermissionAction.SEARCH,
            PermissionAction.SHOW_LCD_TEXT,
            PermissionAction.REPORT_APM_ISSUE,
            PermissionAction.OPEN_COMPARTMENT,
        ],
    },

    [Role.DRIVER]: undefined,
    [Role.DEPOT_WORKER]: undefined,
    [Role.PARTNER]: undefined,
} as const;
